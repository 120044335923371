<template>
  <v-btn
    class="download-wasia-template-btn"
    color="primary"
    elevation="3"
    outlined
    raised
    :href="`${publicPath}wasia-template/${$t('wasiaTemplateFile')}`"
  >
    {{ $t('downloadWasiaTemplateButton') }}
  </v-btn>
</template>

<script>
export default {
  name: 'DownloadWasiaTemplateButton',
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
