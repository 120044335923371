<template>
  <v-container grid-list-s class="pt-4">
    <v-row>
      <v-col col="3" md="3" class="d-none d-sm-flex"><SideNav /> </v-col>
      <v-divider vertical />
      <v-col col="8" md="8" sm="8">
        <v-row>
          <v-container>
            <h2 class="text-h4 font-weight-bold primary--text ">
              {{ $t('willTitle') }}
            </h2>
            <v-divider class="mt-4" />
            <v-row>
              <v-col align-self="center">
                <v-file-input
                  v-model="attachedWill"
                  :label="$t('willUpload')"
                  @change="uploadWill"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="will">
                <a
                  :href="will.will"
                  :download="will.filename"
                  class=" text-decoration-none d-inline-flex"
                >
                  <v-icon x-large color="primary"
                    >mdi-book-account-outline</v-icon
                  >
                  <span
                    class="font-weight-bold  d-flex align-end secondary--text"
                    >{{ will.filename }}
                  </span>
                </a>
              </v-col>
              <v-col align-self="end">
                <v-row class="justify-end pe-3">
                  <DownloadWasiaTemplateButton />
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-row v-if="willError" class="error--text" justify="center">{{
          willError
        }}</v-row>
        <v-divider />
        <v-row>
          <v-container>
            <h2 class="text-h4 font-weight-bold primary--text ">
              {{ $t('otherDocumentsTitle') }}
            </h2>
            <v-divider class="mt-4" />
            <v-row>
              <v-col align-self="center">
                <v-file-input
                  v-model="attachedOtherDocument"
                  :label="$t('otherDocumentUpload')"
                  @change="uploadOtherDocument"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row v-for="document in otherDocuments" :key="document.pk">
              <v-col class="d-flex justify-space-between">
                <a
                  :href="document.document"
                  :download="will.filename"
                  class="text-decoration-none d-inline-flex"
                >
                  <v-icon x-large color="primary"
                    >mdi-book-account-outline</v-icon
                  >
                  <span
                    class="font-weight-bold d-flex align-end secondary--text"
                    >{{ document.filename }}
                  </span>
                </a>
                <button
                  class="text-decoration-none d-inline-flex"
                  @click="removeOtherDocument(document.pk)"
                >
                  <v-icon medium color="secondary">mdi-delete</v-icon>
                </button>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-row
          v-if="otherDocumentsError"
          class="error--text"
          justify="center"
          >{{ otherDocumentsError }}</v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SideNav from '@/components/SideNav.vue'
import DownloadWasiaTemplateButton from '@/components/DownloadWasiaTemplateButton.vue'

export default Vue.extend({
  components: {
    SideNav,
    DownloadWasiaTemplateButton
  },
  data: () => ({
    attachedWill: null,
    attachedOtherDocument: null
  }),
  computed: {
    ...mapGetters([
      'will',
      'willError',
      'otherDocuments',
      'otherDocumentsError'
    ])
  },
  async mounted() {
    await this.retrieveWill()
    await this.getOtherDocuments()
  },
  methods: {
    ...mapActions([
      'postWill',
      'postOtherDocument',
      'retrieveWill',
      'getOtherDocuments',
      'addNotificationAlert',
      'deleteOtherDocument'
    ]),
    async uploadWill() {
      if (this.attachedWill) {
        const formData = new FormData()
        formData.append('will', this.attachedWill)
        await this.postWill(formData)

        if (!this.willError) {
          this.addNotificationAlert({
            type: 'success',
            text: this.$t('willUploadSuccess')
          })
        }
      }
    },
    async uploadOtherDocument() {
      if (this.attachedOtherDocument) {
        const formData = new FormData()
        formData.append('document', this.attachedOtherDocument)
        await this.postOtherDocument(formData)

        if (!this.otherDocumentsError) {
          this.addNotificationAlert({
            type: 'success',
            text: this.$t('otherDocumentUploadSuccess')
          })
        }
      }
    },
    async removeOtherDocument(documentId) {
      await this.deleteOtherDocument(documentId)
      if (!this.otherDocumentsError) {
        this.addNotificationAlert({
          type: 'success',
          text: this.$t('otherDocumentDeleteSuccess')
        })
      }
    }
  }
})
</script>
